/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  var template =
    'sections/paypal-transfers/templates/acp-paypal-transfers.ng.html';

  var url = '/paypal-transfers?type';

  var resolve = {
    pageTitle: /* @ngInject */ function(module, $injector) {
      return $injector.invoke(
        nsUtilProvider.getPageTitle('sections/paypal-transfers')
      );
    },
    module: /* @ngInject */ function($ocLazyLoad) {
      return import(/* webpackChunkName: "acp.section.paypal-transfers" */ 'sections/paypal-transfers').then(
        $ocLazyLoad.loadModule
      );
    }
  };

  $stateProvider
    .state('move-money.paypal-transfers', {
      url: url,
      parent: 'root',
      templateUrl: template,
      controller: 'acpPaypalTransfersSectionCtrl',
      controllerAs: 'vm',
      data: {
        permissions: {
          only: ['paypal_transfers'],
          except: ['isPaypalUnlinkedAccount'],
          redirectTo: {
            isPaypalUnlinkedAccount: 'move-money.paypal-transfers.unlinked'
          }
        }
      },
      resolve: resolve
    })
    .state('move-money.paypal-transfers.unlinked', {
      url: '/paypal-transfers/unlinked',
      parent: 'root',
      controller: 'acpPaypalTransfersSectionCtrl',
      controllerAs: 'vm',
      templateUrl:
        'sections/paypal-transfers/templates/acp-paypal-transfers-unlinked.ng.html',
      resolve: resolve,
      data: {
        permissions: {
          except: ['isPaypalLinkedAccount'],
          only: ['paypal_transfers'],
          redirectTo: {
            isPaypalLinkedAccount: 'move-money.paypal-transfers'
          }
        }
      }
    })
    .state('paypal-transfers-info', {
      url: '/paypal-transfers-info',
      parent: 'root',
      templateUrl:
        'sections/paypal-transfers/templates/acp-paypal-transfers-info.ng.html',
      data: {
        permissions: {
          only: 'isPaypalTransfersInfoEnabled',
          redirectTo: {
            isPaypalTransfersInfoEnabled: 'dashboard'
          }
        }
      },
      resolve: resolve
    });
}

export default states;
